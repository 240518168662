"use strict";

((utils): void => {
    let previousScrollY: number = 0;

    function updateStickyNavbar(): void {
        let navbar: HTMLElement = document.querySelector(".navbar");
        let scrollY: number = window.scrollY;
        let offsetTop: number = navbar.offsetTop;
        let isSticky: boolean = navbar.classList.contains("sticky-top");

        if (utils.isMinWidth(992)) {
            if (scrollY > offsetTop && !isSticky) {
                navbar.classList.add("sticky-top");
            }
            if ((scrollY < offsetTop || scrollY === 0) && isSticky) {
                navbar.classList.remove("sticky-top");
            }
        } else {
            if (scrollY > previousScrollY && scrollY > (navbar.clientHeight * 2)) {
                navbar.style.setProperty("top", `-${navbar.clientHeight}px`);
                if (!isSticky) {
                    navbar.classList.add("sticky-top");
                }
            }
            if (scrollY < previousScrollY) {
                // Reset to default which means 0px defined in CSS
                navbar.style.setProperty("top", "");
                // Ceil the scrollY to fix navbar not showing up on chrome on android
                if ((Math.ceil(scrollY) < offsetTop || scrollY === 0) && isSticky) {
                    navbar.classList.remove("sticky-top");
                }
            }
            previousScrollY = scrollY;
        }
    }

    function initOverflowMenu(): void {
        const navbarCollapse: HTMLElement = document.querySelector(".navbar .navbar-collapse");
        let overflowMenu: HTMLElement = navbarCollapse?.querySelector(".dropdown-overflow .dropdown-menu");

        if (!navbarCollapse || !overflowMenu) {
            return;
        }

        navbarCollapse.querySelector(".navbar-nav").childNodes.forEach((menuItem: HTMLElement): void => {
            overflowMenu.appendChild(menuItem.cloneNode(true));
        });
    }

    function updateOverflowMenu(): void {
        let dropdownOverflow: HTMLElement = document.querySelector(".navbar .navbar-collapse .dropdown-overflow");

        if (!dropdownOverflow) {
            return;
        }

        // Don't bother updating when we're showing mobile menu
        if (!utils.isMinWidth(992)) {
            dropdownOverflow.style.setProperty("display", "none");
            return;
        }

        let navbarNav: HTMLElement = document.querySelector(".navbar .navbar-collapse .navbar-nav");
        let dropdownOverflowMenu: HTMLElement = document.querySelector(".navbar .navbar-collapse .dropdown-overflow .dropdown-menu");
        let firstHiddenIndex: number = 0;
        let itemsCount: number = navbarNav.children.length;

        // Some sort of hack to ensure that all overflown items are in the overflow menu
        // When we display overflow menu after counting firstHiddenIndex we can end up losing one menu item
        dropdownOverflow.style.setProperty("display", "block");

        [...navbarNav.children].forEach((item: HTMLElement): void => {
            if (item.offsetTop === 0) {
                firstHiddenIndex++;
            }
        });

        if (firstHiddenIndex >= itemsCount) {
            dropdownOverflow.style.setProperty("display", "none");
        } else {
            dropdownOverflow.style.setProperty("display", "block");
            [...dropdownOverflowMenu.children].forEach((item: HTMLElement): void => {
                if (firstHiddenIndex > 0) {
                    item.style.setProperty("display", "none");
                    firstHiddenIndex--;
                } else {
                    item.style.setProperty("display", "list-item");
                }
            });
        }
    }

    function showSearch(): void {
        const navbar: HTMLElement = document.querySelector(".navbar-nav");
        const buyButton: HTMLElement = document.querySelector(".buy-btn-container");
        const searchForm: HTMLElement = document.querySelector(".form-inline");

        if (navbar) {
            navbar.style.setProperty("display", "none");
        }
        if (buyButton) {
            buyButton.style.setProperty("display", "none");
        }
        searchForm?.classList.add("searching");

        updateOverflowMenu();
    }

    function hideSearch(): void {
        const navbar: HTMLElement = document.querySelector(".navbar-nav");
        const buyButton: HTMLElement = document.querySelector(".buy-btn-container");
        const searchForm: HTMLElement = document.querySelector(".form-inline");

        if (navbar) {
            navbar.style.setProperty("display", "flex");
        }
        if (buyButton) {
            buyButton.style.setProperty("display", "block");
        }
        searchForm?.classList.remove("searching");

        updateOverflowMenu();
    }

    function handleDropdownShow(e: Event): void {
        bootstrap.Dropdown.getOrCreateInstance((<HTMLElement>e.target).querySelector(".dropdown-toggle, .navbar-toggler")).show();
    }

    function handleDropdownHide(e: Event): void {
        bootstrap.Dropdown.getOrCreateInstance((<HTMLElement>e.target).querySelector(".dropdown-toggle, .navbar-toggler")).hide();
        (<HTMLElement>document.activeElement).blur();
    }

    let mouseOverEventsForDropdownMenusBound: boolean = false;

    function toggleMouseOverEventsForDropdownMenus(): void {
        // Don't bother when we're mobile or touch device
        if (utils.isMobileDevice() || utils.isTouchDevice()) {
            return;
        }

        if (utils.isMinWidth(992)) {
            if (mouseOverEventsForDropdownMenusBound) {
                return;
            }

            [...document.querySelectorAll(".dropdown, .dropstart, .dropend")].map((dropdownMenu: HTMLElement): void => {
                dropdownMenu.addEventListener("mouseenter", handleDropdownShow);
                dropdownMenu.addEventListener("mouseleave", handleDropdownHide);
            });

            mouseOverEventsForDropdownMenusBound = true;
        } else {
            if (!mouseOverEventsForDropdownMenusBound) {
                return;
            }

            [...document.querySelectorAll(".dropdown, .dropstart, .dropend")].map((dropdownMenu: HTMLElement): void => {
                dropdownMenu.removeEventListener("mouseenter", handleDropdownShow);
                dropdownMenu.removeEventListener("mouseleave", handleDropdownHide);
            });

            mouseOverEventsForDropdownMenusBound = false;
        }
    }

    window.addEventListener("load", (): void => {
        initOverflowMenu();
        updateOverflowMenu();
        updateStickyNavbar();
        toggleMouseOverEventsForDropdownMenus();
    });

    let navbarResizeTimeout: any = null;
    window.addEventListener("resize", (): void => {
        clearTimeout(navbarResizeTimeout);
        navbarResizeTimeout = setTimeout((): void => {
            updateOverflowMenu();
            toggleMouseOverEventsForDropdownMenus();
        }, 250);
    });

    document.addEventListener("scroll", (): void => {
        updateStickyNavbar();
    });

    const searchButton: HTMLElement = document.querySelector("#search-btn");
    searchButton?.addEventListener("click", (): void => {
        const searchForm: HTMLElement = document.querySelector(".form-inline");

        if (searchForm.classList.contains("searching")) {
            hideSearch();
        } else {
            showSearch();
        }
    });

    const navbarCollapse: HTMLElement = document.querySelector(".navbar .navbar-collapse");
    navbarCollapse?.addEventListener("show.bs.collapse", (e: Event): void => {
        let target: HTMLElement = (<HTMLElement>e.target);
        let togglerIcon: HTMLElement = target.parentElement.querySelector(".navbar-toggler-icon");
        let navbar: HTMLElement = document.querySelector(".navbar");

        togglerIcon.classList.add("active");
        document.body.classList.add("noscroll");
        navbar.classList.add("navbar-open");
    });

    navbarCollapse?.addEventListener("hidden.bs.collapse", (e: Event): void => {
        let target: HTMLElement = (<HTMLElement>e.target);
        let togglerIcon: HTMLElement = target.parentElement.querySelector(".navbar-toggler-icon");
        let navbar: HTMLElement = document.querySelector(".navbar");

        togglerIcon.classList.remove("active");
        document.body.classList.remove("noscroll");
        navbar.classList.remove("navbar-open");
    });
})((<any>window).Arcas.Utils);
